// Context
import { useCallContext } from "@/context/CallContext";
import { useCustomerSupportContext } from "@/context/CustomerSupportContext";
import { useSchedulesContext } from "@/context/SchedulesContext";
import { useVideoCallContext } from "@/context/VideoCallContext";

// Enums
import { CustomerSupportFeature } from "@/enums/optimizely/CustomerSupportFeature";

// Node Modules
import { useDecision } from "@optimizely/react-sdk";
import { useEffect } from "react";

// Scripts
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";
import {
  resetHasAttractionLoopJustBeenClosedIfCallIsActive,
  validateIfInteractionCallFeatureIsEnabled,
  validateIfSupportModalFeatureIsEnabled
} from "@/scripts/customerSupportHelper";

const CustomerSupportHandler = (): JSX.Element => {
  const [decision] = useDecision(CustomerSupportFeature.Variation, {
    autoUpdate: true,
  });

  const {
    callState,
  } = useCallContext();

  const {
    isIncomingCall,
    videoProvider,
  } = useVideoCallContext();

  const {
    isNscCurrentlyOpen,
    isStaffingCurrentlyOpen,
  } = useSchedulesContext();

  const {
    hasAttractionLoopJustBeenClosed,
    hasInteractionCallBeenTriggeredForThisSession,
    resetHasAttractionLoopJustBeenClosed,
    setCustomerSupportFeature,
    setIsInteractionCallFeatureEnabled,
    setIsSupportModalFeatureEnabled,
  } = useCustomerSupportContext();

  useEffect(() => {
    const isAudioCallStateActive: boolean = !!callState?.callStatus && callState.callStatus !== callStatuses.inactive;
    const isVideoCallStateActive: boolean = isIncomingCall || !!videoProvider?.isCallActive;

    let customerSupportFeatureFromOptimizely: string = "";
    if (decision && decision.enabled && decision.variationKey) {
      customerSupportFeatureFromOptimizely = decision.variationKey;
    }

    const isInteractionCallEnabled = validateIfInteractionCallFeatureIsEnabled(
      customerSupportFeatureFromOptimizely,
      hasInteractionCallBeenTriggeredForThisSession,
      isAudioCallStateActive,
      isNscCurrentlyOpen,
      isVideoCallStateActive,
      !isStaffingCurrentlyOpen);

    setIsInteractionCallFeatureEnabled(isInteractionCallEnabled);

    const isSupportModalEnabled = validateIfSupportModalFeatureIsEnabled(
      hasAttractionLoopJustBeenClosed,
      isAudioCallStateActive,
      isNscCurrentlyOpen,
      customerSupportFeatureFromOptimizely,
      isVideoCallStateActive);

    setIsSupportModalFeatureEnabled(isSupportModalEnabled);
    setCustomerSupportFeature(customerSupportFeatureFromOptimizely);
    resetHasAttractionLoopJustBeenClosedIfCallIsActive(
      isAudioCallStateActive,
      isVideoCallStateActive,
      resetHasAttractionLoopJustBeenClosed);
  }, [
    callState,
    decision,
    hasAttractionLoopJustBeenClosed,
    hasInteractionCallBeenTriggeredForThisSession,
    isIncomingCall,
    isNscCurrentlyOpen,
    isStaffingCurrentlyOpen,
    videoProvider?.isCallActive,
  ]);

  return (
    <>
    </>
  )
}

export default CustomerSupportHandler;
