// Node Modules
import React from "react";

// Themes
import theme from "@/theme";

// Types
import IBannerProps from "@/interfaces/global-components/banner/IBannerProps";

const TopBanner = ({
  backGroundColor,
  className,
  fontColor,
  label,
  testId,
}: IBannerProps) => {
  let formattedLabel = [""];
  
  if (label) {
    formattedLabel = label.split("<br/>");
  }

  return (
    <>
      <div
        className={className
          ? `${className} banner`
          : "banner"}
        data-testid={testId}
      >
        <p>
          {formattedLabel.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
      <style jsx>
        {`
          p {
            font-size: 2.8rem;
            line-height: 1.5;
          }
          
          .banner {
            background-color: ${backGroundColor ?? theme.banners.accentGreen};
            align-items: center;
            color: ${fontColor ?? theme.fontColors.primaryGray};
            display: flex;
            font-weight: bold;
            min-height: 10rem;
            padding: 3rem 5rem;
            justify-content: center;
            width: 100%;
          }
          .system-outage{
            position:sticky;
            top:0;
          }
          .system-outage > p{
            font-size:3.2rem;
          }
        `}
      </style>
    </>
  );
};

export default TopBanner;
