// Constants
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";

// Context
import { useBreezeKioskPaymentContext } from "@/context/BreezeKioskPaymentContext";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";
import { useVideoCallContext } from "@/context/VideoCallContext";
import {
  featureNames,
  useFeatureToggleContext
} from "@/context/FeaturesToggleContext";

// Enum
import { DiagnosticsFeature } from "@/enums/optimizely/DiagnosticsFeature";

// Node Modules
import Pusher from "pusher-js";
import Script from "next/script";
import { useDecision } from "@optimizely/react-sdk";
import { useRouter } from "next/router";
import {
  useEffect,
  useState
} from "react";

// Services
import dataLayerService from "@/components/services/dataLayerService";
import pusherService from "@/components/services/web-socket-notifications/pusherService";
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Types
import BreezeKioskPaymentsChannelSubscription from "@/classes/web-socket-notifications/BreezeKioskPaymentsChannelSubscription";
import IBreezeKioskPaymentsChannelSubscriptionProps from "@/interfaces/web-socket-notifications/pusher/IBreezeKioskPaymentsChannelSubscriptionProps";
import IKioskCallsChannelSubscriptionProps from "@/interfaces/web-socket-notifications/pusher/IKioskCallsChannelSubscriptionProps";
import IKioskDiagnosticsSubscriptionProps from "@/interfaces/web-socket-notifications/pusher/IKioskDiagnosticsSubscriptionProps";
import IStartEventData from "@/interfaces/web-socket-notifications/pusher/breeze-kiosk-payment/IStartEventData";
import KioskCallsChannelSubscription from "@/classes/web-socket-notifications/KioskCallsChannelSubscription";
import KioskDiagnosticsChannelSubscription from "@/classes/web-socket-notifications/KioskDiagnosticsChannelSubscription";
import PusherChannelSubscription from "@/classes/web-socket-notifications/PusherChannelSubscription";

const PusherNotificationsHandler = () => {
  const [diagnosticsDecision] = useDecision(DiagnosticsFeature.Variation, {
    autoUpdate: true,
  });

  const channelSubscriptions: PusherChannelSubscription[] = [];

  let pusherInstance: Pusher;

  const router = useRouter();

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    setIsIncomingCall,
    setWasCallDisconnectedByNscAgent,
  } = useVideoCallContext();

  const {
    setPaymentInformationRequestPayload,
  } = useBreezeKioskPaymentContext();

  const {
    featureToggleList,
  } = useFeatureToggleContext();

  const [useTwilioFlexFunctionality, setUseTwilioFlexFunctionality] = useState<boolean>(false);

  const getKioskDiagnosticsChannelSubscription = (pusherInstance: Pusher) => {
    const props: IKioskDiagnosticsSubscriptionProps = {
      deviceInformation,
      pusherInstance,
      router,
    };

    return new KioskDiagnosticsChannelSubscription(props);
  };

  const getKioskCallsChannelSubscription = (pusherInstance: Pusher) => {
    const props: IKioskCallsChannelSubscriptionProps = {
      deviceInformation,
      pusherInstance,
      dialEventAction: () => setIsIncomingCall(true),
      disconnectEventAction: () => setWasCallDisconnectedByNscAgent(true),
    };

    return new KioskCallsChannelSubscription(props);
  }

  const getBreezeKioskPaymentChannelSubscription = (pusherInstance: Pusher) => {
    const startEventAction = (_data: IStartEventData) => {
      logOnDataDog("Breeze Payment Push - Start Event Action", StatusType.info, _data);

      setPaymentInformationRequestPayload(_data);
      router.push("/payment/breeze-remote-payment/");
    }

    const props: IBreezeKioskPaymentsChannelSubscriptionProps = {
      deviceInformation,
      pusherInstance,
      startEventAction,
      cancelEventAction: () => {
        logOnDataDog("Breeze Payment Push - Cancel Event Action", StatusType.info);

        dataLayerService.pushEvent({
          event: dataLayerTypes.events.paymentCancelledOnKioskByAgent,
        });

        router.push("/")
      },
    };

    return new BreezeKioskPaymentsChannelSubscription(props);
  }

  useEffect(() => {
    const featureToggle = featureToggleList.find(item => item.featureName == featureNames.flex);
    const useTwilioFlex: boolean = !!featureToggle && featureToggle.isEnabled;
    setUseTwilioFlexFunctionality(useTwilioFlex);
  }, [featureToggleList]);

  useEffect(() => {
    try {
      if (!pusherInstance) {
        pusherInstance = pusherService.getPusherInstance();
      }

      if (diagnosticsDecision && diagnosticsDecision.enabled) {
        const kioskDiagnosticsChannelSubscription = getKioskDiagnosticsChannelSubscription(pusherInstance);
        channelSubscriptions.push(kioskDiagnosticsChannelSubscription);
      }

      if (useTwilioFlexFunctionality) {
        const kioskCallsChannelSubscription = getKioskCallsChannelSubscription(pusherInstance);
        channelSubscriptions.push(kioskCallsChannelSubscription);

        const breezeKioskPaymentChannelSubscription = getBreezeKioskPaymentChannelSubscription(pusherInstance);
        channelSubscriptions.push(breezeKioskPaymentChannelSubscription);
      }

      for (const channelSubscription of channelSubscriptions) {
        channelSubscription.registerEvents();
      }
    } catch (error) {
      const errorMessage: string = `PUSHER_ERROR::${error}`;
      logOnDataDog(errorMessage, StatusType.error, error);
    }

    return () => {
      for (const channelSubscription of channelSubscriptions) {
        channelSubscription && channelSubscription.unSubscribe();
      }
      pusherInstance && pusherInstance.disconnect();
    }
  }, [useTwilioFlexFunctionality]);

  return (
    <Script
      id="pusher-script"
      src="https://js.pusher.com/7.2/pusher.min.js"
      type="text/javascript"
    />
  )
}

export default PusherNotificationsHandler;
