// Components
import Button from "@/gc/voice/sub-components/Button";
import Stopwatch from "@/gc/voice/sub-components/Stopwatch";

// Constants
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";

// Context
import { useCallContext } from "@/context/CallContext";
import { useCustomerSupportContext } from "@/context/CustomerSupportContext";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";
import { useTimerContext } from "@/context/TimerContext";
import { useTwilioAuthenticationContext } from "@/context/TwilioAuthenticationContext";

// Enums
import { Brand } from "@/enums/Brand";

// FontAwesome Icons
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";

// Node Modules
import {
  useEffect,
  useState
} from "react";

// Scripts
import { padStartStoreNumber } from "@/scripts/stringUtils";
import {
  callEvents,
  callStatuses,
  deviceEvents
} from "@/scripts/constant-types/voice/twilioConstants";

// Services
import twilioService from "@/services/twilioService";
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Themes
import theme from "@/theme";

// Types
import ICallHandlerProps from "@/interfaces/global-components/voice/ICallHandlerProps";
import ICustomIconDefinitionProps from "@/interfaces/global-components/icons/ICustomIconDefinitionProps";
import dataLayerService from "@/components/services/dataLayerService";
import {
  Call,
  Device
} from "@twilio/voice-sdk";

const CallHandler = ({
  content,
  disabled,
}: ICallHandlerProps) => {
  const globalLayoutFields = content?.globalLayout?.fields;

  const {
    token,
    setToken,
  } = useTwilioAuthenticationContext();

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    startCallFunctionRef,
  } = useCustomerSupportContext();

  const {
    callState,
    setCallState,
    stopWatch,
  } = useCallContext();

  const {
    disableTimer,
    enableTimer,
  } = useTimerContext();

  const [ivrDigits, setIvrDigits] = useState("999999999");
  const [iconDefinition, setIconDefinition] = useState<ICustomIconDefinitionProps>({
    color: theme.brandColors.white,
    icon: faPhoneAlt,
    size: "lg",
  });

  const [isCallDisabled, setCallDisabled] = useState<boolean>(false)

  useEffect(() => {
    const storeNumber = getStoreNumber();
    const storeZipCode = deviceInformation?.zipCode || "99999";
    setIvrDigits(storeZipCode + storeNumber);

    startCallFunctionRef.current = startCall;
  }, []);

  let device: Device | undefined;

  const startCall = async (): Promise<void> => {
    setDialingCallState();

    const audioContext = window.AudioContext || window.webkitAudioContext;
    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    const context = new audioContext();
    context.resume();

    if (!callState.device) {
      const audioToken = await getToken();
      device = new Device(audioToken, {
        codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
      });

      const errorMessage = `An error has occurred with the Twilio Device in the Kiosk in store: ${getStoreNumber()}`;
      device.on(deviceEvents.tokenWillExpire, async () => device!.updateToken(await getToken()));
      device.on(deviceEvents.error, (error) => handleOnErrorEvent(error, errorMessage));

      setDeviceState(device);
    }

    if (!device) {
      device = callState.device;
    }

    const call: Call = await makeOutgoingCall();
    call.on(callEvents.ringing, () => handleOnRingingEvent());
    call.on(callEvents.accept, () => handleOnAcceptEvent(call));
    call.on(callEvents.reconnecting, (error) => handleOnReconnectingEvent(error));
    call.on(callEvents.disconnect, () => handleOnDisconnectEvent());
    call.on(callEvents.error, (error) => handleOnErrorEvent(error));
  };

  const getStoreNumber = () => padStartStoreNumber(Number(deviceInformation?.storeNumber)) || "9999";

  const makeOutgoingCall = async (): Promise<Call> => {
    const numberDialed = (deviceInformation?.brand === Brand.LS)
      ? globalLayoutFields?.lsiPhoneNumber
      : globalLayoutFields?.nscPhoneNumber;

    const countryCode = "+1";
    const nscPhoneNumber = countryCode + numberDialed;
    const twilioPhoneNumber = countryCode + globalLayoutFields?.twilioPhoneNumber;

    return device!.connect({
      params: {
        from: twilioPhoneNumber,
        to: nscPhoneNumber,
      },
    });
  };

  const endCall = (): void => {
    if (callState?.device) {
      callState.device.disconnectAll();
    }
    stopWatch.reset(undefined, false);
  };

  const getToken = async (): Promise<string> => {
    const storeNumber = getStoreNumber();
    const isTokenExpired = twilioService.isTokenExpired(token);

    if (isTokenExpired) {
      const newToken = await twilioService.getAudioToken(storeNumber);
      setToken(newToken);

      return newToken;
    }

    return new Promise(() => token);
  }

  const handleOnAcceptEvent = (call: Call): void => {
    setTimeout(() => {
      call.sendDigits(ivrDigits);
    }, 1000);
    stopWatch.start();

    setCallState(callState => ({
      ...callState,
      buttonLabel: globalLayoutFields?.footerEndCallButtonText!,
      callStatus: callStatuses.active,
    }));

    setIconDefinition(iconDefinition => ({
      ...iconDefinition,
      color: theme.brandColors.white,
    }))
  };

  const handleOnDisconnectEvent = (): void => {
    resetCallState();
    stopWatch.reset(undefined, false);
  };

  const handleOnErrorEvent = (error: any, errorMessage?: string): void => {
    handleOnDisconnectEvent();
    if (!errorMessage) {
      errorMessage = `An error has occurred while processing the call in the Kiosk in store: ${getStoreNumber()}`;
    }
    error = errorMessage + error;
    logOnDataDog(error, StatusType.error);
  };

  const handleOnReconnectingEvent = (error: any): void => {
    const errorMessage = `An error has occurred and the call is reconnecting, this happened in the Kiosk in store: ${getStoreNumber()}`;
    handleOnErrorEvent(error, errorMessage);
  };

  const handleOnRingingEvent = (): void => {
    setDeviceState(device);
  };

  const setDialingCallState = () => {
    dataLayerService.pushEvent({
      event: dataLayerTypes.events.kioskOutboundPhoneCall,
    })

    setCallState(callState => ({
      ...callState,
      buttonLabel: globalLayoutFields?.footerDialingButtonText!,
      callStatus: callStatuses.connecting,
      device,
    }));

    setIconDefinition(iconDefinition => ({
      ...iconDefinition,
      color: theme.surfaceColors.black,
    }));
    disableTimer();
  };

  const setDeviceState = (device: Device | undefined) => {
    setCallState(callState => ({
      ...callState,
      device,
    }));
  };

  const resetCallState = () => {
    setCallState(callState => ({
      ...callState,
      buttonLabel: globalLayoutFields?.footerCallButtonText!,
      callStatus: callStatuses.inactive,
    }));
    setIconDefinition(iconDefinition => ({
      ...iconDefinition,
      color: theme.surfaceColors.white,
    }))
    enableTimer();
  };

  useEffect(() => {
    setCallDisabled(disabled)
  }, [disabled])

  return (
    <>
      <div
        className="call-handler-container"
        role="call-handler-container"
      >
        {isCallDisabled
          ? (
            <Button
              callState={callState}
              content={content}
              disabled={isCallDisabled}
              endCall={undefined}
              iconDefinition={iconDefinition}
              startCall={undefined}
            />
          ) :
          callState.callStatus === callStatuses.active
            ? (
              <>
                <Stopwatch {...stopWatch} />
                <Button
                  callState={callState}
                  content={content}
                  disabled={isCallDisabled}
                  endCall={endCall}
                  iconDefinition={iconDefinition}
                  startCall={startCall}
                />
              </>
            )
            : (
              <Button
                callState={callState}
                content={content}
                disabled={isCallDisabled}
                endCall={endCall}
                iconDefinition={iconDefinition}
                startCall={startCall}

              />
            )}

      </div>

      <style jsx>
        {`
          div.call-handler-container {
            align-items: center;
            background-color: ${theme.brandColors.white};
            display: flex;
            height: 100%;
            justify-content: end;
            width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default CallHandler;
