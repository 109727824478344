// Node Modules
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";

// Styles
import { StyledPrimaryButton } from "./StyledPrimaryButton";

// Themes
import theme from "@/theme";

// Types
import { IPrimaryButtonProps } from "@/interfaces/global-components/primary-button/IPrimaryButtonProps";

const PrimaryButton = ({
  btnWidth = "77%",
  disabled = false,
  icon,
  mainText,
  onClick,
  secondaryText,
  testId,
}: IPrimaryButtonProps) => {
  return (
    <StyledPrimaryButton
      className="primary-button"
      data-testid={testId}
      disabled={disabled}
      width={btnWidth}
      onClick={onClick}
    >
      <div className="btn-container">
        <div className="btn-description-container">
          <p>{mainText}</p>
          {secondaryText && <p>{secondaryText}</p>}
        </div>
        <div className="btn-icon-container">
          
          <FontAwesomeIcon
            color={theme.brandColors.primaryGreen}
            fontSize="3.5rem"
            icon={icon}
            size="3x"
          />
        </div>
      </div>
    </StyledPrimaryButton>
    
  );
};

export default PrimaryButton;
