// Context
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

// Node Modules
import {
  useEffect,
  useState,
} from "react";

// Services
import splashScreenService from "@/components/services/splashScreenService";

// Types
import { IUseStoreDetailsHandler } from "@/interfaces/hooks/store-details/IUseStoreDetailsHandler";
import { StoreState } from "@/src/models/types/StoreState";
import {
  IHoursOfOperationDetails,
  IStoreAddress,
  IStoreDetails
} from "@/interfaces/splash-screen";

const defaultAddress: IStoreAddress = {
  city: "",
  formattedAddress: null,
  line1: "",
  line2: "",
  line3: "",
  line4: "",
  postalCode: "",
  stateAbbreviation: "",
  stateName: "",
}

export const useStoreDetailsHandler = (): IUseStoreDetailsHandler => {
  const [address, setAddress] = useState<IStoreAddress>(defaultAddress);
  const [adminFee, setAdminFee] = useState<number>(0);
  const [contextInitialize, setContextInitialize] = useState<boolean>(false);
  const [storeState, setStoreState] = useState<StoreState>("NotSet");
  const [hoursOfOperation, setHoursOfOperation] = useState<IHoursOfOperationDetails[]>([]);
  
  const {
    ozStoreId,
  } = useDeviceInformationContext();
  
  const getStoreDetails = async (): Promise<IStoreDetails> => {
    return await splashScreenService.getStoreDetails(ozStoreId);
  }

  const revalidateStoreState = async (): Promise<void> => {
    const storeDetailsResult: IStoreDetails = await getStoreDetails();
    getInventoryStatus(storeDetailsResult);
  }

  const getInventoryStatus = (storeDetailsResult: IStoreDetails) => {
    const {
      hasAvailableInventory,
      status,
    } = storeDetailsResult;

    if (status == "D") {
      setStoreState("DisasterStatus");
    } else if (hasAvailableInventory) {
      setStoreState("UnitsAvailable");
    } else if (!hasAvailableInventory) {
      setStoreState("NoUnitsAvailable")
    } else {
      setStoreState("DisasterStatus");
    }
  }

  const initStoreDetailsContext = async (): Promise<void> => {
    const storeDetailsResult: IStoreDetails = await getStoreDetails();
    getInventoryStatus(storeDetailsResult);
    setAddress(storeDetailsResult.address);
    setAdminFee(storeDetailsResult.adminFee);
    setHoursOfOperation([...hoursOfOperation, ...storeDetailsResult.hoursOfOperation]);

    setContextInitialize(true);
  }

  useEffect(() => {
    if (ozStoreId && !contextInitialize) {
      initStoreDetailsContext();
    }
  }, [ozStoreId]);
  
  return {
    address,
    adminFee,
    hoursOfOperation,
    storeState,
    revalidateStoreState,
  }
}
