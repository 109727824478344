// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
// Node Modules
import { useEffect } from "react";

const useOnClickOutside = (ref: React.MutableRefObject<HTMLDivElement | null>, handler: (event?: HTMLDivElement) => void, exceptionRef?: React.MutableRefObject<HTMLDivElement>): void => {
  useEffect(
    () => {
      const listener = getListener(ref, handler, exceptionRef);

      addEventListeners(listener);

      return () => {
        removeEventListeners(listener);
      };
    },

    [ref, handler]
  );
};

export default useOnClickOutside;

function getListener(ref: React.MutableRefObject<HTMLDivElement | null>, handler: (event?: HTMLDivElement) => void, exceptionRef?: React.MutableRefObject<HTMLDivElement>) {
  return (event: any) => {
    const eventWasTriggeredByReference = !ref?.current || ref.current.contains(event.target);
    const eventWasTriggeredOnExceptionReference = !exceptionRef?.current || exceptionRef.current.contains(event.target);
    const triggerEventOnBothElements = !eventWasTriggeredByReference && !eventWasTriggeredOnExceptionReference;
    const triggerEventOnMainElement = !eventWasTriggeredByReference && eventWasTriggeredOnExceptionReference;

    if (triggerEventOnMainElement || triggerEventOnBothElements) {
      handler(event);
    }
    return;
  };
}

function removeEventListeners(listener: (event: any) => void) {
  document.removeEventListener("mousedown", listener);
  document.removeEventListener("touchstart", listener);
}

function addEventListeners(listener: (event: any) => void) {
  document.addEventListener("mousedown", listener);
  document.addEventListener("touchstart", listener);
}
