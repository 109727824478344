const isDefaultValueForSensitiveVariable = (environmentVariableValue: string): boolean => environmentVariableValue === "VALUE_REPLACED_IN_PIPELINE";

/**
 * This function returns the confidence threshold for the camera detection library.
 * @returns CAMERA_DETECTION_FACE_DETECTOR_CONFIDENCE_THRESHOLD value or a default if the variable is not set or is not a number.
 */
const getCameraDetectionConfidenceThreshold = (): number => {
  const envValue: string | undefined = process.env.CAMERA_DETECTION_FACE_DETECTOR_CONFIDENCE_THRESHOLD;
  const value: number = envValue && !isNaN(parseInt(envValue))
    ? parseInt(envValue)
    : 70;

  return value;
};

/**
 * This function returns the reduced volume level that the call chime has to be set to.
 * @returns KIOSK_DIALING_REDUCED_VOLUME_LEVEL value or a default if the variable is not set or is not a number.
 */
const getKioskCallChimeReducedVolumeLevel = (): number => {
  const envValue: string | undefined = process.env.KIOSK_DIALING_REDUCED_VOLUME_LEVEL;
  const value: number = envValue && !isNaN(parseInt(envValue))
    ? parseFloat(envValue)
    : 0.3;

  return value;
};

/**
 * This function returns the number of milliseconds used in the interval that checks the status of the card reader during the payment process.
 * @returns KIOSK_PAYMENTS_CHECK_SET_INTERVAL_MS value or a default if the variable is not set or is not a number.
 */
const getKioskPaymentCheckInterval = (): number => {
  const envValue: string | undefined = process.env.KIOSK_PAYMENTS_CHECK_SET_INTERVAL_MS;
  const value: number = envValue && !isNaN(parseInt(envValue))
    ? parseInt(envValue)
    : 1000;

  return value;
};

const getRevalidateDefaultTimeInSeconds = (): number => {
  const envValue: string | undefined = process.env.REVALIDATE_DEFAULT_TIME_IN_SECONDS;
  const value: number = envValue && !isNaN(parseInt(envValue))
    ? parseInt(envValue)
    : 240;

  return value;
};

const getSystemOutageIntervalInMilliseconds = (): number => {
  const envValue: string | undefined = process.env.SYSTEM_OUTAGES_SET_INTERVAL_MS;
  const value: number = envValue && !isNaN(parseInt(envValue))
    ? parseInt(envValue)
    : 300_000;

  return value;
};

/**
 * This function returns the Twilio Service base url or throws an error if it is not defined.
 * @returns The Twilio service base url.
 */
const getTwilioServiceUrl = (): string => {
  const useTwilioServiceProductionAccount: boolean = process.env.TWILIO_ACCOUNT_NAME == "Production";
  const url: string | undefined = useTwilioServiceProductionAccount
    ? process.env.TWILIO_SERVICE_PROD_BASE_URL
    : process.env.TWILIO_SERVICE_DEV_BASE_URL;

  if (!url || isDefaultValueForSensitiveVariable(url)) {
    throw new Error("Twilio's service url is not defined.");
  }

  return url;
};

/**
 * This function returns the number of maximum attempts to check if the card reader is prompting to use the card
 * or throws an error if it is not defined.
 * @returns KIOSK_PAYMENTS_WAIT_CARD_READER_TO_PROMPT_MAXIMUM_ATTEMPTS or an error.
 */
const getWaitCardReaderToPromptMaximumAttempts = (): number => {
  const value: string | undefined = process.env.KIOSK_PAYMENTS_WAIT_CARD_READER_TO_PROMPT_MAXIMUM_ATTEMPTS;

  if (!value || isDefaultValueForSensitiveVariable(value) || isNaN(parseInt(value))) {
    throw new Error("KIOSK_PAYMENTS_WAIT_CARD_READER_TO_PROMPT_MAXIMUM_ATTEMPTS is not defined.");
  }

  return parseInt(value);
};

export default {
  getCameraDetectionConfidenceThreshold,
  getKioskCallChimeReducedVolumeLevel,
  getKioskPaymentCheckInterval,
  getRevalidateDefaultTimeInSeconds,
  getSystemOutageIntervalInMilliseconds,
  getTwilioServiceUrl,
  getWaitCardReaderToPromptMaximumAttempts,
  isDefaultValueForSensitiveVariable,
};

