const getGAClientId = (): string => {
  let clientId = undefined;

  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("_ga="));

  if (cookie) {
    const gaCookie = cookie.split("=")[1];
    clientId = gaCookie.split(".").slice(-2).join(".");
  }
  if (clientId) {
    return clientId;
  } else {
    return "";
  }
}

const getGASessionId = (): string => {
  let sessionId = undefined;

  const gaCookie = document.cookie
    .split("; ")
    .find((row) => row.includes("_ga_"));

  if (gaCookie) {
    sessionId = gaCookie.split(".")[2];
  }

  if (sessionId) {
    return sessionId;
  } else {
    return "";
  }
}

export default {
  getGAClientId,
  getGASessionId,
}
