// Node Modules
import React, {
  createContext,
  useContext,
  useRef,
  useState,
} from "react";

// Types
import ICustomerSupportContext from "@/interfaces/context/customer-support/ICustomerSupportContext";
import ICustomerSupportContextProvider from "@/interfaces/context/customer-support/ICustomerSupportContextProvider";

const CustomerSupportContext = createContext<ICustomerSupportContext | undefined>(undefined);

const useCustomerSupportContext = () => {
  const context = useContext(CustomerSupportContext);
  if (context === undefined) {
    throw new Error("useCustomerSupportContext must be used within a ICustomerSupportContextProvider");
  }
  return context;
};

const CustomerSupportContextProvider = ({
  children,
}: ICustomerSupportContextProvider): JSX.Element => {
  const [customerSupportFeature, setCustomerSupportFeature] = useState<string>("");
  const startCallFunctionRef = useRef<(() => Promise<void>) | undefined>(undefined);
  const [isSupportModalFeatureEnabled, setIsSupportModalFeatureEnabled] = useState<boolean>(false);
  const [hasAttractionLoopJustBeenClosed, setHasAttractionLoopJustBeenClosed] = useState<boolean>(false);
  const [hasInteractionCallBeenTriggeredForThisSession, setHasInteractionCallBeenTriggeredForThisSession] = useState<boolean>(false);
  const [isInteractionCallFeatureEnabled, setIsInteractionCallFeatureEnabled] = useState<boolean>(false);
  const resetHasAttractionLoopJustBeenClosed = (): void => setHasAttractionLoopJustBeenClosed(false);

  const contextValues: ICustomerSupportContext = {
    customerSupportFeature,
    hasAttractionLoopJustBeenClosed,
    hasInteractionCallBeenTriggeredForThisSession,
    isInteractionCallFeatureEnabled,
    resetHasAttractionLoopJustBeenClosed,
    setCustomerSupportFeature,
    setHasAttractionLoopJustBeenClosed,
    setHasInteractionCallBeenTriggeredForThisSession,
    setIsInteractionCallFeatureEnabled,
    setIsSupportModalFeatureEnabled,
    isSupportModalFeatureEnabled,
    startCallFunctionRef,
  };

  return (
    <CustomerSupportContext.Provider
      value={contextValues}
    >
      {children}
    </CustomerSupportContext.Provider>
  );
};

export {
  CustomerSupportContext,
  CustomerSupportContextProvider,
  useCustomerSupportContext
};
