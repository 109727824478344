// Constants
import { sessionStorageKeys } from "@/scripts/constant-types/localStorageKeys";

const getSessionStorageObject = (sessionStorageObjectKey: string) => {
  const localData = typeof window !== "undefined" && sessionStorage.getItem(sessionStorageObjectKey);
  return localData;
};

const getGaUserSessionId = (): string => {
  const dataFromLocalStorage = typeof window !== "undefined" && getSessionStorageObject(sessionStorageKeys.gaSessionUserId);
  return JSON.parse(dataFromLocalStorage) ?? "";
};

export { getGaUserSessionId };
